<template>
  <div class="backMenu">
    <div class="">
      <el-card class="box-card">
        <div class="MenuTop">
          <div>
            <el-input
              v-model="MenuApi.keywords"
              @keyup.enter.native="getContern"
              @change="MenuApi.page = 1"
              placeholder="请输入内容"
            >
              <template slot="prepend">操作关键字：</template>
            </el-input>
          </div>
          <div class="btn">
            <el-button type="primary" @click="getContern">查 询</el-button>
          </div>
        </div>
        <div class="MenuList scorr-roll">
          <el-table :data="contronList.data" border style="width: 100%">
            <el-table-column label="编号" width="100" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.id }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称"
              width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="时间"
              width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.create_time }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="记录"
              min-width="300"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.rmark"
                  placement="bottom"
                >
                  <div class="exceed">
                    {{ row.rmark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="paging">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="15"
            layout="total, prev, pager, next, jumper"
            :total="contronList.total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power"); //vuex公共库
export default {
  data() {
    return {
      contronList: {},
      MenuApi: {
        keywords: "",
        page: 1,
      },
      isAddBackPerson: false, //弹框
      addData: {},
    };
  },
  created() {
    this.getContern();
  },
  methods: {
    ...mapActions(["posthistory"]),
    // 获取操作记录
    async getContern() {
      let data = await this.posthistory(this.MenuApi);
      //console.log(data, "123");
      this.contronList = data.data;
    },
    // 去新建
    toAddBack() {
      this.isAddBackPerson = true;
    },
    // 翻页
    handleCurrentChange(e) {
      this.MenuApi.page = e;
      this.getContern();
    },
  },
};
</script>
<style lang='scss' scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style lang="scss" scoped>
.backMenu {
  width: 100%;
  .MenuTop {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    .hint-title {
      font-size: 15px;
      font-weight: bold;
    }
    .btn {
      margin-left: 20px;
    }
  }
  .MenuList {
    margin-top: 20px;
    width: 100%;
    max-height: 65vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #06B7AE;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 400px;
      }
    }
  }
}
</style>